import {
  AddComponentHandler,
  EditorSDK,
  ElementData,
  RemoveComponentHandler,
} from '@wix/platform-editor-sdk';
import { HelpId } from '../constants/helpIds';
import { ElementId } from '../editorConstants';
import {
  collapseRefComponent,
  expandRefComponentByRole,
} from '../editorWrapper';
import { TFunction } from '@wix/yoshi-flow-editor';
import { ComponentGfppEvent, WidgetGfppEvent } from './eventHandlers';

const getElementsData = (t: TFunction): ElementData[] => [
  {
    label: t('app.settings.elements.memberImage'),
    identifier: { role: ElementId.memberImage },
    categoryId: 'visibilityCategory',
    index: 0,
  },
  {
    label: t('app.settings.elements.roleDescription'),
    identifier: { role: ElementId.roleDescription },
    categoryId: 'visibilityCategory',
    index: 1,
  },
  {
    label: t('app.settings.elements.companyName'),
    identifier: { role: ElementId.companyName },
    categoryId: 'visibilityCategory',
    index: 2,
  },
  {
    label: t('app.settings.elements.about'),
    identifier: { role: ElementId.about },
    categoryId: 'visibilityCategory',
    index: 3,
  },
  {
    label: t('app.settings.elements.link'),
    identifier: { role: ElementId.link },
    categoryId: 'visibilityCategory',
    index: 4,
  },
];

export const openElementsPanel = async (
  editorSDK: EditorSDK,
  t: TFunction,
  event: ComponentGfppEvent | WidgetGfppEvent,
) => {
  const { componentRef } = event.detail;

  const removeHandler: RemoveComponentHandler = async (compRef, identifier) => {
    await collapseRefComponent(editorSDK, compRef);
    return [identifier];
  };

  const addHandler: AddComponentHandler = async (identifier) => {
    await expandRefComponentByRole(editorSDK, identifier.role);
    return [identifier];
  };

  await editorSDK.editor.openElementsPanel('', {
    widgetRef: componentRef,
    categoriesData: [
      {
        id: 'visibilityCategory',
        title: t('app.settings.elements.groupTitle'),
      },
    ],
    elementsData: getElementsData(t),
    addComponentHandler: addHandler,
    removeComponentHandler: removeHandler,
    helpId: HelpId.ElementsPanel,
  });
};
