import { EditorSDK } from '@wix/platform-editor-sdk';
import {
  ComponentRef,
  FlowEditorSDK,
  IEditorSDKConfig,
} from '@wix/yoshi-flow-editor';
import { LinkAction, OrderedMemberIds } from './types';
import { createWidgetAPI } from '@wix/bob-widget-services';
import {
  ControllerConfigKeys,
  STAFF_WIDGET_APP_DEF_ID,
  Variant,
} from './editorConstants';

export const setProps = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  newProps: any,
) => {
  return editorSDK?.document.application.appStudioWidgets.props.set('', {
    widgetRef,
    newProps,
  });
};

interface CustomWidgetProps {
  staffOrder: OrderedMemberIds;
  linkAction: LinkAction;
}

export const getProps = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
): Promise<CustomWidgetProps> => {
  const props =
    await editorSDK?.document.application.appStudioWidgets.props.get('', {
      widgetRef,
    });
  return props as unknown as CustomWidgetProps;
};

export const getProp = async (
  editorSDK: FlowEditorSDK,
  controllerRefId: string,
  propName: string,
): Promise<any> => {
  const widgetApi = createWidgetAPI(editorSDK?.document as any, '');
  const props = await widgetApi.getWidgetProps(controllerRefId);
  return props[propName];
};

export const setProp = async (
  editorSDK: FlowEditorSDK,
  controllerRefId: string,
  propName: string,
  propValue: any,
) => {
  const widgetApi = createWidgetAPI(editorSDK?.document as any, '');
  widgetApi.updateWidgetProp(controllerRefId, propName, propValue);
};

export const getController = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
) => {
  const [controllerRef] = await editorSDK.document.components.getChildren('', {
    componentRef,
  });
  return controllerRef;
};

export const getParent = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
) => {
  const [parentRef] = await editorSDK.document.components.getAncestors('', {
    componentRef,
  });
  return parentRef;
};

export const getFirstControllerRef = async (editorSDK: EditorSDK) => {
  const allControllers =
    await editorSDK.document.controllers.listAllControllers('');
  const [controllerRef] = allControllers;
  return controllerRef?.controllerRef;
};

export const collapseRefComponent = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
) => {
  await editorSDK.document.components.refComponents.collapseReferredComponent(
    '',
    { componentRef },
  );
};

export const expandRefComponentByRole = async (
  editorSDK: EditorSDK,
  role: string,
) => {
  const refApi = editorSDK.document.components.refComponents;
  const [hostRef] = await refApi.getAllAppRefComponents('');

  const allCollapsedComponents = await refApi.getCollapsedRefComponents('', {
    componentRef: hostRef,
  });

  const collapsedCompToRestore = allCollapsedComponents.find(
    (comp) => comp.role === role,
  );

  if (collapsedCompToRestore?.componentRef) {
    refApi.expandReferredComponent('', {
      componentRef: collapsedCompToRestore.componentRef,
    });
  }
};

export const refreshPreview = (editorSDK: EditorSDK, source = '') => {
  editorSDK.document?.application?.livePreview?.refresh('', {
    shouldFetchData: false,
    source,
  });
};

export const getControllerRef = (editorSDKConfig: IEditorSDKConfig | null) => {
  const { controllerRef } = editorSDKConfig?.initialData;
  return controllerRef;
};

export const getComponentIdentifier = async (editorSDK: EditorSDK | null) => {
  return editorSDK?.document.info.getMetaSiteId('');
};

type GetControllerConfigProp = {
  [ControllerConfigKeys.ORDER_PROP_NAME]: string[];
};
export const getControllerConfigProp = async <
  T extends keyof GetControllerConfigProp,
>(
  editorSDK: EditorSDK,
  controllerRef: ComponentRef,
  key: T,
): Promise<GetControllerConfigProp[T]> => {
  const { config } = await editorSDK.controllers.getData('', {
    controllerRef,
    scope: 'COMPONENT',
  });
  return config?.[key];
};

export const setControllerConfigProp = async (
  editorSDK: EditorSDK,
  controllerRef: ComponentRef,
  key: string,
  value: unknown,
) => {
  const { config } = await editorSDK.controllers.getData('', {
    controllerRef,
    scope: 'COMPONENT',
  });
  await editorSDK.controllers.saveConfiguration('', {
    controllerRef,
    scope: 'COMPONENT',
    config: { ...config, [key]: value },
  });

  refreshPreview(editorSDK!);
};

export const setPresetIfMissing = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
  presetId: string,
) => {
  const currentPreset =
    await editorSDK?.document.application.appStudioWidgets.getPreset('', {
      componentRef,
    });

  if (currentPreset.layout !== Variant.Cards) {
    await editorSDK?.document.application.appStudioWidgets.changePreset('', {
      componentRef,
      layoutPresetId: presetId,
      stylePresetId: presetId,
    });
  }
};

export const isStaffWidgetAppComponent = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
): Promise<boolean> => {
  const data = (await editorSDK.components.data.get('', { componentRef })) as
    | { widgetId: string; appDefinitionId: string }
    | undefined;
  return Boolean(data?.appDefinitionId === STAFF_WIDGET_APP_DEF_ID);
};

export const updateComponentWidth = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
  width: number,
) => {
  await editorSDK.components.layout.update('', {
    componentRef,
    layout: { width },
  });
};

export const getComponentLayout = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
) => {
  return editorSDK.components.layout.get('', { componentRef });
};

export const getComponentPreset = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
) => {
  return editorSDK.document.application.appStudioWidgets.getPreset('', {
    componentRef,
  });
};
