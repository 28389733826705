import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import {
  EditorSDK,
  GfppConfig,
  GfppMobileConfig,
} from '@wix/platform-editor-sdk';

import { MAIN_INTERACTION_NAME, widgetName } from './editorConstants';

import { registerEventListeners } from './editor/eventHandlers';

import { initFields } from './server/members.api';
import { HelpId } from './constants/helpIds';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  { firstInstall },
  flowAPI,
) => {
  if (firstInstall) {
    try {
      flowAPI.fedops.interactionEnded(MAIN_INTERACTION_NAME);
      await flowAPI.httpClient.request(initFields());
      flowAPI.fedops.interactionEnded(MAIN_INTERACTION_NAME);
    } catch (err) {
      flowAPI.errorMonitor?.captureException(err as Error);
    }
  }

  await registerEventListeners(editorSDK as EditorSDK, flowAPI);
};

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
) => {
  const appManifestBuilder = options.appManifestBuilder;
  const { t } = flowAPI.translations;
  const rawManifest = await fetchWidgetsStageData(options as any, t);

  type OptionsWithAppDefID = typeof options & {
    appDefinitionId: string;
  };

  const { appDefinitionId } = options as OptionsWithAppDefID;
  const mobileGfpp = (
    rawManifest.controllersStageData[`${appDefinitionId}-${widgetName}`].default
      ?.gfpp as GfppConfig
  )?.mobile as GfppMobileConfig;

  if (mobileGfpp) {
    mobileGfpp.mainAction2 = 'HIDE';
  }

  const desktopGfpp = (
    rawManifest.controllersStageData[`${appDefinitionId}-${widgetName}`].default
      ?.gfpp as GfppConfig
  )?.desktop as GfppMobileConfig;

  if (desktopGfpp) {
    desktopGfpp.helpId = HelpId.StaffWidget;
    // @ts-expect-error // need to move this to use manifest builder
    // https://github.com/wix-private/editor-platform/blob/master/packages/platform-editor-sdk/docs/RFCs/rfc-manifest-builder.md
    desktopGfpp.iconButtons.connect = { behavior: 'HIDE' };
  }

  return appManifestBuilder.withJsonManifest(rawManifest).build();
};
