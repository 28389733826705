import { EditorSDK } from '@wix/platform-editor-sdk';
import { ComponentRef, IHttpClient } from '@wix/yoshi-flow-editor';
import { getMembers } from '../server/members.api';
import { Variant } from '../editorConstants';
import {
  getComponentLayout,
  getComponentPreset,
  updateComponentWidth,
} from '../editorWrapper';

const ONE_CARD_WIDTH = 320;
const CARDS_MARGIN = 10;
const FULL_WIDTH = 980;
const TWO_CARDS_WIDTH = ONE_CARD_WIDTH * 2 + CARDS_MARGIN;

export const setWidgetForNewWidget = async (
  editorSDK: EditorSDK,
  httpClient: IHttpClient,
  componentRef: ComponentRef,
  isMembersAboutV2Enabled: boolean = false,
) => {
  const { data: members } = await httpClient.request(
    getMembers({
      roleIds: [],
      isMembersAboutV2Enabled,
    }),
  );
  if (members.length === 1) {
    await updateComponentWidth(editorSDK, componentRef, ONE_CARD_WIDTH);
  }
};

// Better UX when adding staff members
// EditorX needs different logic
export const setSuitableWidgetWidth = async (
  editorSDK: EditorSDK,
  compRef: ComponentRef,
  membersCount: number,
) => {
  const currentPreset = await getComponentPreset(editorSDK, compRef);
  const isSideBySideLayout = currentPreset.layout === Variant.SideBySide;
  const isCardsLayout = currentPreset.layout === Variant.Cards;
  const { width: currentWidth } = await getComponentLayout(editorSDK, compRef);

  if (membersCount === 1) {
    await updateComponentWidth(editorSDK, compRef, FULL_WIDTH / 2);
  }

  if (isCardsLayout && membersCount === 2 && currentWidth < TWO_CARDS_WIDTH) {
    await updateComponentWidth(editorSDK, compRef, TWO_CARDS_WIDTH);
  }

  if (isSideBySideLayout && membersCount < 3 && currentWidth < FULL_WIDTH) {
    await updateComponentWidth(editorSDK, compRef, FULL_WIDTH);
  }

  if (isCardsLayout && membersCount === 3 && currentWidth < FULL_WIDTH) {
    await updateComponentWidth(editorSDK, compRef, FULL_WIDTH);
  }
};
