import { instanceValue } from '@wix/wix-instance-parser-js';
import webBiLogger from '@wix/web-bi-logger';
import { useMemo } from 'react';

import { STAFF_WIDGET_APP_DEF_ID } from '../../../../editorConstants';

export const getBiLogger = (instance: string, withClientId = false) => {
  const logger = webBiLogger.factory().logger();
  const baseProps = {
    app_id: STAFF_WIDGET_APP_DEF_ID,
    instance_id: instanceValue.getValueFromInstance('instanceId', instance),
    biToken: instanceValue.getValueFromInstance('biToken', instance),
    ...(withClientId
      ? { client_id: instanceValue.getValueFromInstance('uid', instance) }
      : {}),
  };

  logger.updateDefaults(baseProps);

  return logger;
};

export const useBi = (instance: string, withClientId?: boolean) =>
  useMemo(() => getBiLogger(instance, withClientId), [instance, withClientId]);
