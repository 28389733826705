import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import {
  ControllerConfigKeys,
  INITIALLY_VISIBLE_MEMBERS,
} from '../editorConstants';
import {
  getControllerConfigProp,
  setControllerConfigProp,
} from '../editorWrapper';
import { getMembers } from '../server/members.api';
import { OrderedMemberIds } from '../types';
import { Experiment } from '../types/experiments';

export const initProperties = async (
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
  controllerRef: ComponentRef,
) => {
  try {
    const { httpClient } = flowAPI;
    if (!controllerRef) {
      return;
    }
    const currentProp = await getControllerConfigProp(
      editorSDK,
      controllerRef,
      ControllerConfigKeys.ORDER_PROP_NAME,
    );

    if (!currentProp?.length) {
      const { data: members } = await httpClient.request(
        getMembers({
          roleIds: [],
          isMembersAboutV2Enabled: flowAPI.experiments.enabled(
            Experiment.UseMembersAboutV2,
          ),
        }),
      );

      if (members) {
        const staffOrder: OrderedMemberIds = members
          .slice(0, INITIALLY_VISIBLE_MEMBERS)
          .map((member) => member._id);

        await setControllerConfigProp(
          editorSDK,
          controllerRef,
          ControllerConfigKeys.ORDER_PROP_NAME,
          staffOrder,
        );
      }
    }
  } catch (error) {
    flowAPI.errorMonitor.captureException(error as Error);
  }
};
