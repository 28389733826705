import {
  EditorSDK,
  EventType,
  gfppOriginStrings,
} from '@wix/platform-editor-sdk';
import { ComponentRef, FlowAPI, TFunction } from '@wix/yoshi-flow-editor';
import {
  openPresetPanel,
  openStaffPanel,
} from '../components/t45j1/panels/utils/config';
import { openElementsPanel } from './elementsPanel';
import { PanelType, Variant } from '../editorConstants';
import {
  getController,
  isStaffWidgetAppComponent,
  setPresetIfMissing,
} from '../editorWrapper';
import { initProperties } from '../services/properties';
import { setWidgetForNewWidget } from '../services/componentWidth';
import { Experiment } from '../types/experiments';

export type WidgetGfppEvent = CustomEvent<{
  id: string;
  componentRef: ComponentRef;
  gfppOrigin: gfppOriginStrings;
}>;

export type ComponentGfppEvent = CustomEvent<{
  id: string;
  role: string;
  componentRef: ComponentRef;
  controllerRef: ComponentRef;
}>;

export const registerEventListeners = async (
  editorSDK: EditorSDK,
  flowAPI: FlowAPI,
) => {
  await editorSDK.removeAllListeners();
  await editorSDK.addEventListener(
    EventType.widgetGfppClicked,
    async (event) => {
      handleMainEvents(editorSDK, flowAPI, event);
    },
  );
  await editorSDK.addEventListener(
    EventType.componentGfppClicked,
    async (event) => {
      handleMainEvents(editorSDK, flowAPI, event);
    },
  );
  await editorSDK.addEventListener(
    EventType.anyComponentAddedToStage,
    async (event) => {
      const { compRef } = event.detail;
      if (await isStaffWidgetAppComponent(editorSDK, compRef)) {
        await waitForControllerAndInit(editorSDK, flowAPI, event);
        await setPresetIfMissing(editorSDK, compRef, Variant.Cards);
        await setWidgetForNewWidget(
          editorSDK,
          flowAPI.httpClient,
          compRef,
          flowAPI.experiments.enabled(Experiment.UseMembersAboutV2),
        );
      }
    },
  );
  await editorSDK.addEventListener(
    EventType.connectedComponentAddedToStage,
    async (event) => {
      const { compRef } = event.detail;
      await waitForControllerAndInit(editorSDK, flowAPI, event);
      await setPresetIfMissing(editorSDK, compRef, Variant.Cards);
      await setWidgetForNewWidget(
        editorSDK,
        flowAPI.httpClient,
        compRef,
        flowAPI.experiments.enabled(Experiment.UseMembersAboutV2),
      );
    },
  );
};

const waitForControllerAndInit = async (
  editorSDK: EditorSDK,
  flowAPI: FlowAPI,
  event?: any,
) => {
  const retrieveController = async () => {
    const controllerRef = await getController(editorSDK, event.detail.compRef);
    if (controllerRef) {
      await initProperties(editorSDK, flowAPI, controllerRef);
      return true;
    }
    return false;
  };
  await waitFor(retrieveController);
};

const waitFor = (fn: () => Promise<boolean>) =>
  new Promise<void>(async (resolve) => {
    const maxRetries = 10;
    const tick = 200;
    const wait = async (iteration = 0) => {
      if (iteration <= maxRetries) {
        const result = await fn();
        if (result) {
          resolve();
        } else {
          setTimeout(async () => {
            await wait(++iteration);
          }, tick);
        }
      }
    };
    await wait();
  });

const handleMainEvents = (
  editorSDK: EditorSDK,
  flowAPI: FlowAPI,
  event: ComponentGfppEvent | WidgetGfppEvent,
) => {
  switch (event.detail.id) {
    case PanelType.Preset:
      openPresetPanel(editorSDK, event, flowAPI.translations.t as TFunction);
      break;
    case PanelType.Display:
      openElementsPanel(editorSDK, flowAPI.translations.t as TFunction, event);
      break;
    case PanelType.Staff:
      openStaffPanel(editorSDK as EditorSDK, event, flowAPI);
      break;
    /* Map this action to the original "Change Text & Icon" GFPP button in blocks config */
    /* https://app.asana.com/0/1200264060337339/1202110258958266 */
    case PanelType.Link:
      const { componentRef } = event.detail;
      editorSDK.editor.openNativeComponentPanel('', 'settings', {
        componentRef,
        panelSectionsDefinition: { link: 'hidden' },
      });
      break;
    default:
      break;
  }
};
