import { EditorSDK } from '@wix/platform-editor-sdk';
import { AppManifestEventHandler } from '@wix/app-manifest-builder/dist/types/events/eventHandlerTypes';
import {
  AdvancedGfppTarget,
  GfppClickEventType,
} from '@wix/app-manifest-builder/dist/types/controller/gfpp/types/gfppCommonTypes';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { FlowAPI, TFunction } from '@wix/yoshi-flow-editor';
import { staffWidgetActions } from '@wix/bi-logger-members-app-users/v2';

import { widgetName } from '../../../../editorConstants';
import { HelpId } from '../../../../constants/helpIds';
import {
  ComponentGfppEvent,
  WidgetGfppEvent,
} from '../../../../editor/eventHandlers';
import { getBiLogger } from './bi';

export const openStaffPanel = async (
  editorSDK: EditorSDK,
  event: ComponentGfppEvent | WidgetGfppEvent,
  flowApi: FlowAPI,
) => {
  try {
    const instance = await editorSDK.info.getAppInstance('');
    const logger = getBiLogger(instance);

    logger.report(
      staffWidgetActions({
        action: 'click',
        value: 'staff_button',
      }),
    );
  } catch (e) {
    flowApi.reportError(e as Error);
  }

  return createCustomPanelOnClick({
    title: flowApi.translations.t('app.settings.staff.title'),
    editorSDK,
    panelComp: 'StaffPanel',
    height: 420,
    width: 288,
    helpId: HelpId.StaffPanel,
  })(event);
};

export const openPresetPanel = (
  editorSDK: EditorSDK,
  event: ComponentGfppEvent | WidgetGfppEvent,
  t: TFunction,
) =>
  createCustomPanelOnClick({
    title: t('app.settings.preset.title'),
    editorSDK,
    panelComp: 'PresetPanel',
    height: 200,
    width: 288,
    helpId: HelpId.PresetPanel,
  })(event);

export const openLinkPanel = (
  editorSDK: EditorSDK,
  event: ComponentGfppEvent | WidgetGfppEvent,
  t: TFunction,
) =>
  createCustomPanelOnClick({
    title: t('app.settings.link.title'),
    editorSDK,
    panelComp: 'LinkPanel',
    height: 200,
    width: 288,
  })(event);

export const createCustomPanelOnClick =
  ({
    title,
    editorSDK,
    panelComp,
    helpId,
    height,
    width,
  }: {
    title: string;
    editorSDK: EditorSDK;
    panelComp: string;
    helpId?: string;
    height: number;
    width: number;
  }): AppManifestEventHandler<GfppClickEventType<AdvancedGfppTarget>> =>
  async ({ detail }) => {
    const instance = await editorSDK.info.getAppInstance('');
    const controllerRef = (detail as any).controllerRef || detail.componentRef;
    editorSDK.editor.openComponentPanel('', {
      title,
      url: getPanelUrl(widgetName, panelComp),
      componentRef: detail.componentRef,
      initialData: {
        controllerRef,
        instance,
      },
      height,
      width,
      helpId,
    });
  };
