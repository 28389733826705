
  var editorScriptEntry = require('/home/builduser/work/fa85d65a9160597f/packages/staff-widget-bob/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/fa85d65a9160597f/packages/staff-widget-bob/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = {
        DSN: 'https://6188ee10b4f74ec08c2aec0b378eb3d4@sentry.wixpress.com/2494',
        id: '6188ee10b4f74ec08c2aec0b378eb3d4',
        projectName: 'staff-widget-bob',
        teamName: 'members-area',
        errorMonitor: true,
      };
      const experimentsConfig = {"centralized":true,"scopes":[]};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/fa85d65a9160597f/packages/staff-widget-bob/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
      const defaultTranslations = {"app.widget.welcome":"Welcome","app.widget.gfpp.staff":"Members","app.widget.gfpp.display":"Display","app.widget.gfpp.preset":"Preset","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","app.settings.staff.title":"Members Display","app.settings.staff.hideAction":"Hide","app.settings.staff.showAction":"Show","app.settings.staff.memberGroupDropdownLabel":"Choose which members to display","app.settings.staff.members":"Featured Members","app.settings.staff.filter.all":"All members","app.settings.staff.filter.staffMembers":"Featured Members","app.settings.staff.filter.blogWriters":"Blog writers","app.settings.staff.filter.eventSpeakers":"Event speakers","app.settings.staff.filter.forumModerators":"Forum admins","app.settings.staff.search.placeholder":"Search members...","app.settings.preset.title":"Preset","app.settings.preset.subtitle":"Choose a preset","app.settings.preset.cards":"Cards","app.settings.preset.sideBySide":"Side by side","app.settings.preset.mobile":"Mobile","app.settings.elements.groupTitle":"Show & hide widget elements","app.settings.elements.memberImage":"Image","app.settings.elements.roleDescription":"Role description","app.settings.elements.companyName":"Company name","app.settings.elements.about":"About","app.settings.elements.link":"Link","app.settings.elements.memberName":"Member name","app.settings.elements.profileButton":"Profile button","app.settings.link.title":"Link","app.settings.link.label":"Choose where the button leads to","app.settings.link.actionProfile":"Member profile","app.settings.link.actionServices":"Bookings services"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'staff-widget-bob',
        appDefId: 'cffc6740-8042-48cc-a35b-d3fd03a69f0c',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/fa85d65a9160597f/packages/staff-widget-bob/src/components/t45j1/editor.controller.ts');

      const model_0 = require('/home/builduser/work/fa85d65a9160597f/packages/staff-widget-bob/src/components/t45j1/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "cffc6740-8042-48cc-a35b-d3fd03a69f0c-t45j1";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"cffc6740-8042-48cc-a35b-d3fd03a69f0c-t45j1": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "cffc6740-8042-48cc-a35b-d3fd03a69f0c-t45j1", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
